<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    class="modal"
    max-width="550px"
    min-height="610px"
  >
    <v-card class="fill-height pa-5">
      <div class="ma-auto">
        <v-container class="modal__container d-flex flex-column justify-center align-center pt-5 px-4">
          <p class="modal__title mb-5 text-center">
            Rango a Exportar
          </p>
          <p class="modal__message mb-5">
            Seleccione el rango de días en los cuales se va a descargar la información. Cada tabla representa un día.
          </p>

          <v-form class="form" ref="form">
            <div class="fecha-input">
              <p class="fecha-input__label">
                Desde
              </p>
              <v-menu
                v-model="desdeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="fecha-input__input"
                    readonly
                    v-on="on"
                    v-bind="attrs"
                    :rules="[rules.required]"
                    :value="desdeDateFormattedMomentjs"
                    outlined
                    placeholder="**/**/****">
                  </v-text-field>
                </template>
                <v-date-picker
                  color="#363740"
                  v-model="desde"
                  @input="desdeMenu = false"
                  locale="es-ES"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="fecha-input">
              <p class="fecha-input__label">
                Hasta
              </p>
              <v-menu
                v-model="hastaMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="fecha-input__input"
                    readonly
                    v-on="on"
                    v-bind="attrs"
                    :rules="[rules.required]"
                    :value="hastaDateFormattedMomentjs"
                    outlined
                    placeholder="**/**/****">
                  </v-text-field>
                </template>
                <v-date-picker
                  color="#363740"
                  v-model="hasta"
                  @input="hastaMenu = false"
                  locale="es-ES"
                ></v-date-picker>
              </v-menu>

            </div>
          </v-form>
        </v-container>

        <v-spacer/>

        <v-card-actions>
          <v-btn
            width="230px"
            @click="confirm()"
            elevation="0"
            rounded
            height="38px"
            color="#363740"
            class="modal__button mx-auto"
            :loading="loading"
            dark>
            Descargar
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from '@/utils/rules'
import moment from 'moment'

export default {
  name: 'RangoModal',
  inheritAttrs: false,
  props: ['loading'],
  data () {
    return {
      desde: '',
      hasta: '',
      rules: {
        required
      },
      desdeMenu: false,
      hastaMenu: false,
    }
  },
  computed: {
    desdeDateFormattedMomentjs () {
      return this.formatDate(this.desde)
    },
    hastaDateFormattedMomentjs () {
      return this.formatDate(this.hasta)
    },
  },
  methods: {
    confirm () {
      if (this.$refs.form.validate()) {
        this.$emit('confirm', { fechaInicio: this.desde, fechaFin: this.hasta })
      }
    },
    formatDate (date) {
      return date ? moment(date).format('DD/MM/YYYY') : ''
    },
    close() {
      this.$refs.form.resetValidation()
      this.desde = ''
      this.hasta = ''
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  &__title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #333333;
  }

  &__message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__button {
    background: #363740;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: 0;
  }

  &__container {
    width: 370px;
  }
}

::v-deep .v-dialog {
  border: 1px solid rgba(102, 102, 102, 0.5);
  border-radius: 24px;
}

.fecha-input {
  &__label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #111111;
    margin-bottom: 7px;
    padding: 0 8px;
  }
}

.fecha-input__input ::v-deep .v-input__slot fieldset {
  border: 1px solid #D9D9D9;
  border-radius: 8px;
}

.form {
  width: 300px;
}


</style>
