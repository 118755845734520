<template>
  <v-container class="pa-0">
    <v-btn width="207px" class="change-view-button mr-7" color="white" elevation="0"
           @click="$router.push({name: 'Sensores', params: {codigo}})">
      Cambiar a vista anterior
    </v-btn>

    <v-btn width="207px" class="change-view-button" color="white" elevation="0" @click="mostrarRangoModal=true"
           @confirm="exportarData">
      Exportar data
    </v-btn>

    <v-card elevation="0" class="tabla-general mt-5">
      <v-simple-table>
        <thead>
        <tr>
          <th class="pa-0" style="min-width: 90px;max-width: 90px;width: 90px;">
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  solo
                  elevation="0"
                  class="date-activator"
                  dense
                  :value="computedDateFormattedMomentjs"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-chevron-down"
                  @click:append="dateMenu=true"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#363740"
                v-model="date"
                @input="dateMenu = false; doFetchRegistroSensores()"
                locale="es-ES"
              ></v-date-picker>
            </v-menu>
          </th>
          <template v-if="headersLoading">
            <th class="tabla-general__header" style="width: 100%">
              <v-progress-circular indeterminate></v-progress-circular>
            </th>
          </template>
          <template v-else>
            <th v-for="item in sensoresHeaders" :key="item" class="tabla-general__header" v-text="item"/>
          </template>
        </tr>
        </thead>
        <tbody>
        <template v-if="Object.entries(registros).length > 0">
          <tr v-for="(registro, key) in registros" :key="`registro-${key}`">
            <td class="tabla-general__subheader">{{ key.split(' ')[1] }}</td>
            <td v-for="(header, index) in sensoresHeaders"
                :key="`indice(${index})-valor(${registro[header]})-registro(${key})`">{{ registro[header] }}
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td :colspan="sensoresHeaders.length" v-if="registrosLoading">
              <v-progress-circular indeterminate></v-progress-circular>
            </td>
            <td v-else :colspan="headersLoading? 1: sensoresHeaders.length">
              {{headersLoading? '': 'No hay registros de los sensores en esta fecha.'}}
            </td>
          </tr>
        </template>
        </tbody>
      </v-simple-table>
    </v-card>
    <rango-modal ref="rangoModal" :loading="descargaLoading" v-model="mostrarRangoModal" @confirm="exportarData"
                 @close="mostrarRangoModal=false"></rango-modal>
  </v-container>
</template>

<script>
import moment from 'moment'
import axios from '@/plugins/axios'
import RangoModal from '@/components/modals/RangoModal'

export default {
  name: 'TablaGeneralSensores',
  components: {
    RangoModal
  },
  props: ['codigo'],
  data () {
    return {
      dateMenu: false,
      date: moment().format('YYYY-MM-DD'),
      mostrarRangoModal: false,
      sensores: [],
      registros: {},
      timeout: null,
      registrosLoading: false,
      descargaLoading: false,
      headersLoading: false,
    }
  },
  computed: {
    computedDateFormattedMomentjs () {
      return this.date ? moment(this.date).format('DD/MM') : ''
    },
    sensoresHeaders () {
      const registroAsArray = Object.values(this.registros)
      const isRegistrosEmpty = registroAsArray.length === 0
      if(isRegistrosEmpty) {
        return this.sensores.map(sensor => sensor.codigo)
      }else {
        return Object.keys(registroAsArray[0])
      }
    }
  },
  methods: {
    doFetchRegistroSensores () {
      clearTimeout(this.timeout)
      console.log('cambia')
      this.timeout = setTimeout(async () => {
        console.log('busca')
        await this.fetchRegistroSensores()
      }, 600)
    },
    async fetchSensores () {
      this.headersLoading = true
      const sensoresResponse = await axios.get(`api/iot/dispositivos/${this.codigo}/sensores/`)
      this.sensores = sensoresResponse.data
      this.headersLoading = false
    },
    async fetchRegistroSensores () {
      this.registrosLoading = true
      this.registros = {}
      const registrosResponse = await axios.post('api/iot/registros/por_sensores/', {
        fecha_inicio: this.date,
        fecha_fin: this.date,
        sensores_ids: this.sensores.map(sensor => sensor.id),
        nombres_completos: false
      })
      this.registros = registrosResponse.data
      this.registrosLoading = false
    },
    async exportarData (e) {
      try {
        this.descargaLoading = true
        const fechaInicio = e.fechaInicio
        const fechaFin = e.fechaFin
        const dataResponse = await axios.post(`api/iot/registros/generar_csv/`, {
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          sensores_ids: this.sensores.map(sensor => sensor.id)
        }, { responseType: 'blob' })
        const fileFormattedEndDate = fechaFin.replaceAll('/', '-')
        const fileFormattedStartDate = fechaInicio.replaceAll('/', '-')
        const url = window.URL.createObjectURL(new Blob([dataResponse.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.codigo}_${fileFormattedStartDate}_${fileFormattedEndDate}.csv`)
        document.body.appendChild(link)
        link.click()
        this.descargaLoading = false
        this.$refs.rangoModal.close()
        this.$root.$confirm({ title: 'Descarga exitosa', message: '' })
      } catch (e) {
        this.$root.$confirm({ message: 'Ha ocurrido un error. Inténte de nuevo en unos minutos.' })
        this.mostrarRangoModal = false
      }
    }
  },
  async mounted () {
    await this.fetchSensores()
    await this.fetchRegistroSensores()
  }
}
</script>

<style scoped lang="scss">
.table-container {
  border: 1px solid #DFE0EB;
  border-radius: 8px;
}

.change-view-button {
  text-transform: none;
  border: 1px solid #DFE0EB !important;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #252733;
}

.tabla-general {
  border: 2px solid #9B9B9B;
  border-radius: 8px;

  &__header {
    font-weight: 700;
    font-size: 12px !important;
    line-height: 15px;
    color: white !important;
    background-color: #363740;
    max-width: 47px;
    padding: 5px !important;
    text-align: center !important;
  }

  &__subheader {
    font-weight: 700;
    font-size: 12px !important;
    line-height: 15px;
    color: white !important;
    background-color: #363740;
    padding: 5px !important;
    text-align: center !important;
    min-width: 68px;
  }
}

.tabla-general ::v-deep table {
  border-collapse: collapse;
}

.tabla-general ::v-deep td {
  text-align: center;
  padding: 8px;
}

.tabla-general ::v-deep tr:nth-child(even) {
  background-color: #dddddd;
}

.date-activator {
  max-width: 65px;
  min-width: 65px;
  margin: auto;
}

.date-activator ::v-deep input {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.date-activator ::v-deep .v-input__slot {
  box-shadow: none !important;
  padding: 0 6px !important;
}

.date-activator ::v-deep .v-input__icon {
  min-width: 12px;
  width: 12px;
  cursor: pointer;
}

.date-activator ::v-deep .v-icon.v-icon {
  font-size: 15px;
  font-weight: 600;
}

.date-activator ::v-deep .v-input__append-inner {
  padding-left: 2px;
  cursor: pointer;
}

</style>
